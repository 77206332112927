import { QUESTS_CONFIG } from "./quests.consts";
import { ActiveQuest, GenericQuestConfig, GenericQuestStepConfig, Quest, QuestConfig, QuestGroup } from "./quests.types";

export const getQuestConfigFromActiveQuest = (quest: ActiveQuest<QuestGroup>): QuestConfig<QuestGroup> | undefined => {
  const questCfg = QUESTS_CONFIG[quest.group];
  if (questCfg) {
    const step = (questCfg.quests as QuestConfig<QuestGroup>[]).find((s) => s.id === quest.quest);
    return step || undefined;
  }
};

export const getQuestsStepConfig = (activeQuest: ActiveQuest<QuestGroup>): GenericQuestStepConfig | undefined => {
  const quest = getQuestConfigFromActiveQuest(activeQuest);
  if (quest) {
    const step = quest?.steps.find((ss) => ss.id === activeQuest.step);
    return step || undefined;
  }
};

export const getNextQuestStep = (activeQuest: ActiveQuest<QuestGroup>): GenericQuestStepConfig | undefined => {
  const quest = getQuestConfigFromActiveQuest(activeQuest);
  if (quest) {
    const i = quest.steps.findIndex((s) => s.id === activeQuest.step);
    return i === quest.steps.length - 1 ? undefined : quest.steps[i + 1];
  }
};

export const getQuestConfigFromQuestId = (quest: Quest): GenericQuestConfig | undefined => {
  let config: GenericQuestConfig | undefined;

  Object.keys(QUESTS_CONFIG).find((g: QuestGroup) => {
    const match = (QUESTS_CONFIG[g].quests as QuestConfig<typeof g>[]).find(
      (q: QuestConfig<typeof g>) => q.id === quest
    );
    if (match) {
      config = match;
    }
    return !!match;
  });

  return config;
};
